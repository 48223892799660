import { delegate } from '../utils/delegate.js';

/**
 * Sets up a simple accordion on elements. To set one up, include the following DOM structure:
 *
 * <span class='accordion-trigger' data-accordion-target="#to-hide"></span>
 * <div id='to-hide' class='hide'></div>
 *
 * Clicking on the accordion trigger will toggle display of the target element.
 *
 * Use 'hide' if you need the initial display to be off.
 */

delegate({
  baseSelector: 'body',
  selector: '.accordion-trigger',
  eventName: 'click',
  handler: (e) => {
    const targetSelector = e.target.dataset.accordionTarget;
    const titleSelector = e.target.dataset.titleInputTarget;
    const descriptionSelector = e.target.dataset.descriptionInputTarget;

    const target = document.body.querySelector(targetSelector);
    const title = document.body.querySelector(titleSelector);
    const description = document.body.querySelector(descriptionSelector);

    const requiredLabel = ' <abbr title="required">*</abbr>';

    if (target.classList.contains('hide')) {
      target.classList.remove('hide');
      title.required = true;
      description.required = true;

      title.labels[0].innerHTML += requiredLabel;
      description.labels[0].innerHTML += requiredLabel;

      title.labels[0].classList.remove('optional');
      title.labels[0].classList.add('required');
      description.labels[0].classList.remove('optional');
      description.labels[0].classList.add('required');
    } else {
      target.classList.add('hide');
      title.required = false;
      description.required = false;
    }
  }
});
